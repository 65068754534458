<template>
    <v-dialog
        v-model="dialog"
        width="500"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"

            >
                Upload Mark Sheet
            </v-btn>
        </template>

        <v-card v-if="dialog">
            <v-card-title
                class="text-h5 primary white--text"
            >Upload Mark Sheet</v-card-title>
            <v-card-subtitle class="pa-3 mt-3">
                <v-alert
                    type="info"
                >
                    Please download and use the template below to prepare your data. Uploads that don’t match the template format may be rejected.
                </v-alert>
            </v-card-subtitle>
            <v-card-text>
                <v-file-input
                    v-model="file"
                    show-size
                    label="Click to select file to upload."
                    @change="selectFile"
                    outlined
                ></v-file-input>

                <div v-if="file">
                    <v-progress-linear
                        v-model="progress"
                        color="primary"
                        height="25"
                        reactive
                    >
                        <strong>{{ progress }} %</strong>
                    </v-progress-linear>
                </div>

                <v-alert v-if="message" border="left" :color="messageColor" class="mt-6" dark>
                    {{ message }}
                </v-alert>

            </v-card-text>

            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mr-2" @click="downloadTemplate">Download Template</v-btn>
                <v-btn @click="close" depressed>Cancel Upload</v-btn>
            </v-card-actions>

            <v-overlay
                :value="overlay"
                absolute
                color="grey lighten-5"
            >
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="blue"
                ></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UploadService from "../services/UploadFileService";
export default {
    data: () => ({
        dialog: false,
        file: null,
        progress: 0,
        message: null,
        messageColor: "",
        overlay: false,
    }),

    computed: {
        ...mapGetters({
            id: 'auth/getEmployeeId',
        })
    },

    methods: {
        ...mapActions({
            downloadMarkSheetUploadTemplate: 'termReports/downloadMarkSheetUploadTemplate',
        }),

        async selectFile(file) {
            this.progress = 0;
            this.file = file;
            this.message = null;

            try {
                await UploadService.uploadMarkSheet(this.file, this.id, (event) => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                })
                this.$emit('updateMarkSheet');
                this.close();
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                this.message = error.response ? error.response.data.message : "Error uploading file.";
                this.messageColor = "red";
            }
        },

        downloadTemplate () {
            this.overlay = true;
            this.downloadMarkSheetUploadTemplate()
                .then(response => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const now = new Date().getTime();
                    link.setAttribute('download', `Mark Sheet Upload Template - ${now}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    this.overlay = false;
                })
                .catch( error => {
                    error.response ? console.log(error.response) : console.log(error);
                    this.overlay = false;    
                })
        },

        close () {
            this.dialog = false;
            this.file = null;
            this.progress = 0;
            this.message = null;
            this.messageColor = "";
        },
    }


}
</script>