import axios from 'axios';

class UploadFileService {
    
    upload(file, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);

        return axios.post("/api/store-registration-file", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    } 

    uploadMarkSheet(file, employeeId, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);
        formData.append("employeeId", employeeId)

        return axios.post("/api/upload-marksheet", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    } 
    
    
}

export default new UploadFileService();